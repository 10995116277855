import './modules/modals';
import './modules/forms';
import './modules/masks';
import './modules/spinner';
import './modules/scroll';
import './modules/menus';
import './modules/preview-slider/index';
import './modules/tabs';
import './modules/galleries';
import './modules/rotators';
import './modules/filters';
import './modules/catalogue';
import './modules/alphabet-filter';
import './modules/back-button';
import './modules/card';
import './modules/spoilers';
import './modules/profile';
import './modules/chat';
import './modules/promocode';
import './modules/switch-to-whatsapp';
import './modules/filter/index';
import './modules/catalog-page/index';
import './modules/collection/index';
import './modules/sendbasket';
import './modules/link';
import './modules/sorting';
import './modules/share';
import ClosableTeaser from './modules/widgets/closable-teaser';
import { $WINDOW, IS_DESKTOP, TABLET_WIDTH } from './modules/globals';


// Init closable teasers
// ---------------------

const $preHeader = $('.js-pre-header');
if ($preHeader.length) {
  const storageKey = `TEASER_HIDE_DATE_${$preHeader.data('storage-id')}`;
  const teaser = new ClosableTeaser({
    selector: '.js-pre-header',
    closeSelector: '.js-close-pre-header',
    storageKey,
    daysToHide: 7,
  });
}

// const $promocodeLink = $('.js-promocode-popup-link');
// if ($promocodeLink.length) {
//   const storageKey = 'PROMOCODE_TEASER_HIDE_DATE';
//   const teaser = new ClosableTeaser({
//     selector: '.js-promocode-popup-link',
//     closeSelector: '.js-close-promocode-teaser, .promocode-popup .js-close-popup',
//     storageKey,
//     daysToHide: 7,
//   });
// }


$(function (){
  $('.reviews-slider').slick({
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  });
  $('.s-arrow.left').click(function(event){
    event.preventDefault();
    $(".reviews-slider").slick('slickPrev');
  });
  $('.s-arrow.right').click(function(event){
    event.preventDefault();
    $(".reviews-slider").slick('slickNext');
  });
})
$(function () {
  $('.reviews-star').each(function () {
    let rating = parseInt($(this).data('rating'));
    let over = 5 - rating;

    for (let i = 0; i < rating; i++) {
      $(this).append(drawStar("#ffa209"));
    }
    for (let i = 0; i < over; i++) {
      $(this).append(drawStar("#fff"));
    }
  });

  function drawStar(color) {
    return '<svg xmlns="http://www.w3.org/2000/svg" width="20.501" height="19.593" viewBox="0 0 20.501 19.593"><path id="Контур_359" data-name="Контур 359" d="M10.2.758l2.478,5.865,6.344.545a.5.5,0,0,1,.285.876L14.5,12.213l1.442,6.2a.5.5,0,0,1-.745.541L9.741,15.668,4.289,18.956a.5.5,0,0,1-.745-.541l1.442-6.2L.173,8.043a.5.5,0,0,1,.285-.876L6.8,6.622,9.28.758a.5.5,0,0,1,.921,0Z" transform="translate(0.51 0.048)" fill="' + color + '" stroke="#ffa10a" stroke-width="1"></path></svg>';
  }
});
$(function () {
  $('.review .comment').each(function () {
    if ($(this).height() > 50) {
      $(this).addClass('short-text');
      $(this).next().removeClass('hide');
    } else {
      $(this).removeClass('short-text');
      $(this).next().addClass('hide');
    }
  });
  $(function () {
    let maxHight = 300;
    $('.review-slide').each(function () {
      if ($(this).height() > maxHight) {
        maxHight = $(this).height();
      }
    }).find('.review').css('min-height', maxHight);
  });
  $(document).on('click', '.review .more', function (event) {
    event.preventDefault();
    $(this).prev().removeClass('short-text');
    $(this).remove();
  });
});

$('.header--search .btn').on('click', function (){
  if ($('.header--search .inp').val().length > 2) {
    $('.header--search .inp').addClass('ui-autocomplete-loading');
  }
});

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && $('#designers-5')) {
  $('#designers-5').attr("src","/i/designers-5-mobile.jpg");
  $('#designers-5').css('margin-left', ($(window).width() - 298)/2 + 'px');
}

class GRecaptchaV3 {
  /**
   * @type {number}
   */
  timeout;

  /**
   * @type {[]}
   */
  activityEvents;

  /**
   * @type {number}
   */
  secondsSinceLastActivity;

  constructor() {
    this.timeout = 5000;
    this.activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart'
    ];
    this.secondsSinceLastActivity = 0;

    this.init()
  }

  init() {
    this.activityWatcher();

    this.setRecaptchaToken();
    setInterval(() => this.refreshRecaptchaV3Token(), this.timeout);
  }

  setRecaptchaToken() {
    grecaptcha.ready(function () {
      grecaptcha.execute('6LdroUMiAAAAAEsKgMAYnnrLqu3C-o1EgGDDX51r', {action: 'homepage'}).then(function (token) {
        document.cookie = 'gRecaptchaV3=' + token;
        console.log(token)
      });
    });
  }

  refreshRecaptchaV3Token() {
    if (this.secondsSinceLastActivity < this.timeout/1000) {
      this.setRecaptchaToken();
    }
  }

  activityWatcher(){
    let gRecaptchaV3 = this;

    setInterval(function(){
      gRecaptchaV3.secondsSinceLastActivity++;
    }, 1000);

    function activity(){
      gRecaptchaV3.secondsSinceLastActivity = 0;
    }

    gRecaptchaV3.activityEvents.forEach(function(eventName) {
      document.addEventListener(eventName, activity, true);
    });
  }
}

new GRecaptchaV3();

if (window.location.href.match(/\/\/$/)) {
  window.location.href = window.location.href.replace(/[\/]{2,}$/, '/');
}

$('.sale_style_menu_item').each((_, el) => {
  if($(el).attr('href').includes('/http')) {
      $(el).attr('href', $(el).attr('href').substring(1));
  }
})

let tenevoy = $('.tenevoy');

if (tenevoy.length) {
  let tenevoyTitles = tenevoy.html().split(' ');

  if (IS_DESKTOP && $WINDOW.width() >= TABLET_WIDTH) {
    tenevoy.css('text-transform', 'capitalize');
    tenevoy.html(tenevoyTitles[0]);
  }

  setInterval(() => {
    if (IS_DESKTOP && $WINDOW.width() >= TABLET_WIDTH) {
      tenevoy.css('text-transform', 'capitalize');
      tenevoy.html(tenevoy.html() == tenevoyTitles[0] ? tenevoyTitles[1] : tenevoyTitles[0])
    } else {
      tenevoy.css('text-transform', 'none');
      tenevoy.html(tenevoyTitles.join(' '));
    }
  }, 2500);
}